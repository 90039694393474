import React, {useState, useEffect} from 'react'
import "./TiqoHomePage.css"
import { BiLockAlt } from "react-icons/bi"
import { HiPencilAlt } from "react-icons/hi"



function DummyTiqoHome() {

    const documents=[{
        status: "Done - ChatGPT",
        thumb_url: "https://img.youtube.com/vi/XeLaEGQ72Ic/maxresdefault.jpg",
        title: "How do you air-condition a World Cup?",
    }]


    function trimString(str) {
        if (str.length > 52) {
          str = str.substr(0, 52) + "...";
        }
        return str;
      }
    


  return (
    <div className='tiqo-homeback'>
        <div className='tiqo-home-center-div'>

            <div className='tiqo_options'>
                <div className='tiqo_options_text'>
                    <p className='tiqo_options_acc_text coloured_tiqo_opt'> TIQO - Youtube (Beta)</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - PDF</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - Slides</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - Websites</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO W/ ChatGPT</p>
                    <br/>
                    <a href='/'><p className='tiqo_options_acc_text_small'>Project Msimbo</p></a>
                    <br/>
                </div>
            </div>

            <div className='tiqo_collection'>
                    <div className='add_quiz'>
                        <div className='tiqo-quiz-trans'>
                            <p className='tiqo-quiz-cdown'>Sign in to create your own quizzes.</p>
                        </div>
            
                        <div className='tiqo-add-quiz-entire-div'>
                        <a href='/tiqolnk'>
                            <div className='adjust-tiqo-add'>
                                <div className='add_log'>+ </div>
                                <span className='add_tiqo'>Add a new quiz</span>

                            </div>

                        </a>
                        </div>
                    </div>



                <div className='collection_box'>
                    {documents.length>0 ? documents.map(doc =>(

                        doc.status.toLowerCase() !== "pending ..." 
                        
                        ? 

                            <div className='tiqo_card'>
                                
                                <img className='the' src={`${(doc.thumb_url !== null) ? doc.thumb_url : "https://thumbs.dreamstime.com/b/e-learning-education-concept-flat-vector-illustration-people-accessing-knowledge-online-library-online-education-books-177859305.jpg"}`}></img>

                                <p className='tiqo_card_inf'>Title: {`${trimString(doc.title)}`}</p>
                                <p className='tiqo_card_inf'>Status: {`${doc.status}`}</p>

                                <div className='quiz-flas-tiqo'>
                                    <span className='quiz-flas-tiqo-btn'> <a href="/tiqoexflash">Flash cards</a></span>
                                    <span className='quiz-flas-tiqo-btn broke-ahh'>Quiz</span>
                                    <span className='quiz-flas-tiqo-btn broke-ahh'><HiPencilAlt/></span>
                                </div>
                            </div>
                    
                        :

                        <div className='tiqo_card'>
                            <img className='the' src={`${doc.thumb_url}`}></img>

                            <p className='tiqo_card_inf'>Title: {`${trimString(doc.title)}`}</p>
                            <p className='tiqo_card_inf'>Status: {`${doc.status}`}</p>
                        </div>

                    

                    )) : 
                    
                    <div className='empyt-tiqo-lst'>
                     <p className='no-videos-tiqo'><em>Things are awfully quiet around here, try adding a video with "Add a new quiz"</em></p>
                    
                    </div>}


                </div>


            </div>

        </div>

    </div>
  )
}

export default DummyTiqoHome