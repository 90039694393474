import React, {useState, useEffect} from 'react'
import './TiqoFlash.css'
import {useParams} from 'react-router-dom'

function DummyTiqoFlash() {

    const [currentQuiznum,setCurrentQuiznum] = useState(1)
    const [flipped,setfFlipped] = useState(false)


   
	// const quests = ["What is one particular challenge associated with understanding identity, according to the text?",	
	// 				"Why is it difficult to come up with a definitive answer to the question 'Who am I?' despite the involvement of intelligent and creative individuals?",
	// 				"What is the philosophical paradox presented in the story of the Ship of Theseus, and how does it relate to the concept of identity?",
	// 				"How did the Athenians honor Theseus and his heroic feat?",
	// 				"the context of the story, does Ship A (the ship Theseus docked in Athens) equal Ship B (the ship sailed by the Athenians 1000 years later)?",
	// 				"According to the text, why do some argue that A (Ship A) equals B (Ship B)?",
	// 				"How do others counter the argument that A equals B?",
	// 				"What connection is made between the Ship of Theseus and the reader?",
	// 				"Why is the question 'Who am I' considered complex according to the text?",
	// 				]
	// const ans = ["The text highlights the challenge of the complex concept of the persistence of identity. It raises the question of which version of ourselves truly represents our identity. Is it the person we are today, who we were five years ago, or who we will become in 50 years? Additionally, the text raises the issue of determining the timeframe of 'am' - whether it refers to this week, today, this hour, or even this very second.",	
	// 			"The text suggests that the lack of a consensus on the answer to the question 'Who am I?' is due to the wide range of hypotheses put forth by intelligent and creative individuals from various fields of study. The concept of identity is complex and multifaceted, and different perspectives and approaches have contributed to the lack of a definitive answer. The diversity of ideas and the inherent subjectivity of personal identity make it challenging to reach a conclusive understanding.",
	// 			"The philosophical paradox in the story of the Ship of Theseus revolves around the persistence of identity. The ship, initially sailed by Theseus, gradually undergoes replacement of every single part over the course of 1000 years until no original parts remain. The question arises: How can something remain the same entity if all its parts have been replaced? This paradox challenges our understanding of identity and raises questions about what defines an object's essence.",
	// 			"To honor Theseus and his heroic feat of slaying the Minotaur, the Athenians maintained his ship in the harbor for 1000 years and annually reenacted his voyage.",
	// 			"The text leaves the question open-ended and prompts us to consider whether Ship A is equal to Ship B. It raises a philosophical question about the persistence of identity and challenges us to contemplate whether the replacement of every part changes the fundamental nature of the ship.",
	// 			"Some argue that A equals B because the changes made to the Ship of Theseus happened gradually over time, and it never ceased to be the legendary ship. Despite having no common parts, they consider the two ships numerically identical, meaning they are one and the same.",
	// 			"Others counter the argument by emphasizing that Theseus never set foot on Ship B, and his presence on the ship is an essential qualitative property of the Ship of Theseus. They believe that without Theseus, the ship cannot truly be the same. Therefore, although numerically identical, the two ships are not qualitatively identical.",
	// 			"The text suggests that like the Ship of Theseus, the reader is a collection of constantly changing parts, including their physical body, mind, emotions, circumstances, and quirks. The analogy implies that the question of identity and its persistence relates to the reader's own sense of self.",
	// 			"The question 'Who am I?' is considered complex because, like the Ship of Theseus, the reader's identity is multifaceted and constantly evolving. It involves navigating the philosophical paradox of understanding how changing parts contribute to one's sense of self. The text suggests that finding an answer to this question requires delving into the depths of philosophical inquiry."
	// 		]

	const quests=[
		"What was the reason for shifting the Qatar 2022 World Cup to November and December?",
		"How have the stadiums in Qatar been designed to address the heat?",
		"Besides air conditioning, what other passive cooling methods are deployed in Qatar's stadiums?",
		"How do the retractable roofs in Qatar's stadiums contribute to the cooling process?",
		"What is the purpose of the photovoltaic panels on the roof of the Khalifa International Stadium?",
		"What are absorption chillers and how are they used in Qatar's stadiums?",
		"How is the air filtered and cleaned before being cooled down again in the air conditioning system?",
		"What research has Qatar invested in regarding stadium airflows?",
		"What challenges might other nations face in implementing Qatar's stadium cooling technologies?",

	]


	const ans=[
		"The summer temperatures in Qatar, regularly over 40 degrees Celsius, prompted the shift of the tournament to cooler months.",
		"The stadiums in Qatar have been designed with an east-west axis orientation for maximum shade, a white color scheme to reduce heat absorption, and measures to prevent heat from entering the stadiums.",
		"Qatar's stadiums use passive cooling methods such as orientation for shade, strategic color schemes, and designs that prevent heat from entering the stadiums.",
		"When closed, the retractable roofs in Qatar's stadiums improve the efficiency of the cooling process, reducing the burden on water and energy usage for air conditioning systems.",
		"The photovoltaic panels generate electricity that powers the motors of the dual compressor chillers in the stadium.",
		"Absorption chillers, despite their cost and energy requirements, are remarkable machines that utilize solar power to superheat water, enabling the chilling of a separate circuit of water. These chillers play a crucial and impactful role in effectively cooling the air in Qatar's stadiums.",
		"The air is sucked back into the air conditioning system through grilles behind the seats, where it undergoes filtration and cleaning to remove dust and microbes before being cooled and released back into the stadium.",
		"Qatar has invested in a decade's worth of research in fluid dynamics to study the airflows in stadium spaces, allowing for precise calibration of the angle and speed of air movement to maximize cooling and minimize discomfort for spectators.",
		"The immense investment required in solar technologies, district cooling, and high-end chillers could pose a challenge for other nations considering the adoption of Qatar's stadium cooling technologies."
	]

	const qlength = ans.length;


	const incrementQz = () => {
		if (currentQuiznum < qlength){
			setCurrentQuiznum(currentQuiznum+1);
			if (flipped == true){
				buttonclick();
			}
		}
	}

	const decrementQz = () => {
		if (currentQuiznum > 1){
			setCurrentQuiznum(currentQuiznum-1);
			if (flipped == true){
				buttonclick();
			}
		}
	}

	function buttonclick(){
        var pagebutton= document.getElementById("tiqo-main-label-flashcards");
        pagebutton.click();
		if (flipped == true){
			reverseFlipped()
		}
    }

	function reverseFlipped(){
		setfFlipped(!flipped);
	}

   

  return (
    <div className='tiqoflash-whole'>
        <div >
		<label id="tiqo-main-label-flashcards" className='click-me'>
			<input id="my-cool-input" type="checkbox" />
			<div className="flip-card" onClick={reverseFlipped}>
				<div class="front">
					<br/>
					<br/>
					
					{qlength ? <h1>Question {`${currentQuiznum}`}/{`${qlength}`}:</h1> : <></>}
					<br/>
					<br/>
					<br/>
					<div className='answer-tiqo'>
						{ (quests) ? <p className='q_anser'>{`${quests[currentQuiznum-1]}`}</p> : <></>}

					</div>
				</div>
				<div className="back">
					<h1>Answer:</h1>
					<br/>
					<br/>
					<br/>
					<div className='answer-tiqo'>

						{ (quests) ? <p className='q_anser'>{`${ans[currentQuiznum-1]}`}</p> : <></>}
					</div>
				</div>
			</div>
		</label>
		</div>
			<div className='nav-tiqo'>
				<div className='buttons-tiqo-nav'>
					<button onClick={decrementQz} className='neej'>Back</button>
					<button onClick={incrementQz} className='neej'> Next</button>
				</div>
				<a className='back-to-main-tiqo' href="/tiqohome">Back to home page</a>
			</div>
    </div>
	
  )
}

export default DummyTiqoFlash