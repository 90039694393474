import React,{useState} from 'react'
import './payment.css'
import { db } from './firebase'

function Payment() {

    const [name,setName] = useState('');
    const [num,setNum] = useState('');
    const [email,setEmail] = useState('');
    const [emailConfirm,setEmailConfirm] = useState('');
    const [fPage, setFPage] = useState(1);

    function clear(){
        setNum("");
        setEmail("");
        setEmailConfirm("");
    }

    const handleSwitch = (e) => {
        e.preventDefault();

        if(fPage === 1){
            setFPage(2);
        }else{
            setFPage(1);
        }
    }

    let datenow= new Date();
    const ts=datenow.getMinutes()+""+""+datenow.getSeconds();

    const handleSubmit = (e) =>{
        e.preventDefault();

        if ((num.length === 12) && (email.length > 8) && (email === emailConfirm)){
            db.collection("tempDump").doc(email.slice(0,4)+ts.toString()).set({
                email: email,
                phone: num,
                name: email.slice(0,10)
              }).then(function() {
                alert("Your account will be activated shortly if payment was successful.")
                window.location.replace("/");
              }).catch(function(error) {
                alert("Error, please try again.");
                clear();
              });
            

            } 
            else{
                alert("Emails don't match or wrong formating of other details");
                clear();

            }
    }

  return (
    <div className='payment-main'>
        <div class="pay-card">
            <div class="card-leftside">
                <img src="https://img.freepik.com/ 3premium-vector/student-woman-with-laptop-studying-online-course-online-education-concept-vector-illustration-flat_186332-1147.jpg?w=2000" className='card-vector' alt="Product image"/>
            </div>

            {fPage===1 ?
            
            <div class="card-rightside">
                <form onSubmit={handleSwitch}>
                    <a href='/'><button type="button" id="back-button-payments" className='back-button-payments card-button'>Home Page</button></a>
                    <h2 className='card-heading'>Payment Steps</h2>

                    <h3 className='payment-steps'>Step 1: Go to Mpesa App and click on Lipa na M-PESA.</h3>
                    <h3 className='payment-steps'>Step 2: Select Buy Goods and Services.</h3>
                    <h3 className='payment-steps'>Step 3: Enter the following till number: 8132504 (Name: Brian Karuga).</h3>
                    <h3 className='payment-steps'>Step 4: Enter the Ksh 6,000 course fee and complete the transaction.</h3>
                    <h3 className='payment-steps'>Step 6: Finally, fill in the short form on the next page with your details.</h3>
                    <h3 className='payment-steps' style={{textAlign:"center",fontSize:"medium"}}>Your account will be activated within 3 minutes of processing the payment.</h3>

                


                    <div className='card-button-container'>
                        <button type='submit' onSubmit={handleSwitch} className='card-button'>Continue</button>
                    </div>

                </form>
            </div>
            
            :
            
            <div class="card-rightside">
                <a href='/'><button type="button" id="back-button-payments" className='card-button'>Home Page</button></a>
                <h2 className='card-heading'>Payment Information</h2>


                <p className='card-field'>MPESA number starting with 254</p>
                <input className='card-input'
                       type="text" 
                       required
                       placeholder='E.g.: 254712345678'
                       value={num}
                       onChange={(e) => setNum(e.target.value)}>
                </input>

                <p className='card-field'>Sign up email</p>
                <input className='card-input'
                       type="text" 
                       required
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}>
                </input>

                <p className='card-field'>Confirm sign up email</p>
                <input className='card-input'
                       type="text" 
                       required
                       value={emailConfirm}
                       onChange={(e) => setEmailConfirm(e.target.value)}>
                </input>


                <div className='card-cost'>
                    <p className='card-cost-price'>Course Total: </p>
                    <p>Ksh 6,000</p>
                </div>

                <div className='card-button-container-second-page'>
                    <button onClick={handleSwitch} className='card-button'>Prevous Page</button>

                    <button onClick={handleSubmit} className='card-button'>Confirm Details</button>
                </div>

        </div>

            }

        </div>
    </div>
  )
}

export default Payment