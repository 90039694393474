import React from 'react'
import './course.css'
import { TbCertificate } from "react-icons/tb"
import { BiTimeFive } from "react-icons/bi"
import { MdOutlineVideoLibrary } from "react-icons/md"
import { AiOutlineArrowRight } from "react-icons/ai"
import { AiOutlineUnorderedList } from "react-icons/ai"
import { GiHamburgerMenu } from "react-icons/gi"
import {useState, useEffect} from 'react'
import {db} from './firebase'


import { ImCross } from 'react-icons/im'

function Course({user, signOut}) {

    const [open,setOpen] = useState("closed");
    var userEmailSamll = user.email && user.email.toLowerCase();
    const [userModules,setUserModules] =useState([])
    const [modules,setModules] = useState([]);
    const [status,setStatus] = useState("");
    const [userVidTot,setVidTot] = useState({});
    const [userQuizTot,setUserQuizTot] = useState({});

    
    function getQt(){
        let tquiz=0;
        let tvids=0;
        if (modules){
            modules.forEach(module =>{
                tquiz += parseInt(module.tquiz);
                tvids += parseInt(module.tvids);
            })
        }
        return [tquiz,tvids]
        
    }

    // if (modules){
    //     modules.forEach(module =>{

    //         console.log(parseInt(module.tquiz))
    //     })
    // }

    let Tquiz=getQt()[0];
    let Tvids=getQt()[1];

    

    const new_ = (userVidTot.videos && userQuizTot.quiz)  ?
                parseInt(((userVidTot.videos.length + userQuizTot.quiz.length)/(Tvids+Tquiz)*100))
                : "0";

    // if(userQuizTot.quiz && userVidTot.videos){
    //     console.log( userQuizTot)
    //     // console.log(typeof userQuizTot)
    //     // console.log(userVidTot)
    //     //console.log(parseInt(((userVidTot.videos.length + userQuizTot.quiz.length)/(Tvids+Tquiz)*100)))
    // }

    function getNew(){
        if (userQuizTot.quiz && userVidTot.videos){
            return parseInt(((userVidTot.videos.length + userQuizTot.quiz.length)/(Tvids+Tquiz)*100))
        }else{
            return "0"
        }
    }


    useEffect(()=>{
        db.collection("users_2").doc(userEmailSamll).onSnapshot(snapshot => {
            setStatus(snapshot.data().status)
            console.log(snapshot.data().status)
        })

    },[status,modules,userQuizTot])

    //AREA NOT DYNAMIC!!    
    useEffect(()=>{
        db.collection("users_2").doc(user.email).collection("total").doc("videos").onSnapshot(snapshot =>{
            setVidTot({
                videos:snapshot.data().vlst
            })
        })        

    },[modules])
    
    //AREA NOT DYNAMIC!!    
    useEffect(()=>{
        db.collection("users_2").doc(user.email).collection("total").doc("quizes").onSnapshot(snapshot =>{
            setUserQuizTot({
                quiz:snapshot.data().qlst
            })
        })       
        
    },[modules])
    
    
    useEffect(()=>{
        db.collection("modules").get()
        .then(data => {
            setModules(data.docs.map( doc => ({
                diff:doc.data().diff,
                dsc:doc.data().dsc,
                mindex:doc.data().mindex,
                mlink:doc.data().mlink,
                tl:doc.data().tl,
                tquiz:doc.data().tquiz,
                tvids:doc.data().tvids,
                title_id:doc._.id,
            })
            ))
        })
    }
    
    ,[])

    //AREA NOT DYNAMIC!!    
    useEffect(()=>{
        db.collection("users_2").doc(user.email).collection("modules").get()
        .then(data => {
            setUserModules(data.docs.map( doc => ({
                quiz:doc.data().quizes,
                vids:doc.data().videos,
                title_id:doc._.id,
            
            })
            ))
            // console.log(data);
        })        
    }
    
    ,[modules])


    function sortObj(list, key) {
        function compare(a, b) {
            a = a[key];
            b = b[key];
            var type = (typeof(a) === 'string' ||
            typeof(b) === 'string') ? 'string' : 'number';
            var result;
            if (type === 'string') result = a.localeCompare(b);
            else result = a - b;
            return result;
        }
        return list.sort(compare);
    }
    
    function percToDeg(num){
        return (num/100)*360
    }
    sortObj(modules,"mindex")
    
    const signOutFunc = () => {
        if(user.email){
            signOut()
        }else{
            window.location.replace("/login")
        }
    }
    
    function bringMenu(){
        setOpen("open")
    }
    
    function closeMenu(){
      setOpen("closed")
    }
    
    function getPercBarForVids(id){
        if(userModules && modules){
            let correct;
            let identity = modules[id].title_id;
            correct = userModules.filter(module => module.title_id === identity)[0]
            if ((correct !== undefined) && (correct.vids)){

                return parseInt((correct.vids.length/modules[id].tvids)*100)
            }else{
                return 0
            }
        }   
    }

    function getPercBarForQuiz(id){
        if(userModules && modules){
            let correct;
            let identity = modules[id].title_id;
            correct = userModules.filter(module => module.title_id === identity)[0]
            if ((typeof correct !== undefined) && ( correct && correct.quiz)){
                //console.log(correct.quiz)
                let leng = correct.quiz.length;
                let qlengtodo = modules[id].tquiz;

                if (leng >0){
                    // console.log("---------------ID: "+id+'-------')
                    // console.log("length"+leng)
                    // console.log(qlengtodo)
                    let result= parseInt((leng/qlengtodo)*100)
                    //console.log("result"+result)
                    return result

                }else if(leng === qlengtodo ){
                    return 100
                }else{
                    return 0
                }

                 
            }else{
                return 0
            }
        }
    }

    let conicstyle ={
        margin:"auto",
        borderRadius:"50%",
        width:"100px",
        height:"100px",
        background: new_ ? `conic-gradient(#851A3D ${percToDeg(new_)}deg, #9FC7AA 0deg)` : "conic-gradient(#851A3D 0deg, #9FC7AA 0deg)",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        transition: "all 0.2s",
    }
    
    function getStyleForLessBar(id){
        let mystyleLesson={
            backgroundColor: "#851A3D",
            height:"100%",
            width:`${getPercBarForVids(id)}%`,
            //width:"20%",
            borderRadius:"3px",
            "@media (max-width: 800px)": {
                backgroundColor: "blue",
              }
        };
        return mystyleLesson;
    }

    function getStyleForQuizBar(id){
        let mystyleAssignment={
            backgroundColor: "#851A3D",
            height:"100%",
            width:`${getPercBarForQuiz(id)}%`,
            //width:"30%",
            borderRadius:"3px",
        };
        return mystyleAssignment;
    }

  return (
    <div className='course'>
        <section className="home-landing"> 

            <div className='msimbo-title'>
            <a  className='msimbo-title-link' href='/'>Project msimbo</a>
            <GiHamburgerMenu className='ham_menu' onClick={bringMenu}/>
            </div>  

            <nav className={`offerings ${open}`}>
                <ImCross className='close-phone-menu' onClick={closeMenu}/>
                <a className='list-item list-offering-item-top' href='/'>Home</a>
                <a className='list-item' style={{textDecorationLine:"underline"}}>Course</a>
                {/* <a className='list-item'>Pricing</a> */}
                <a href="https://briangitaukr.web.app/" className='list-item'>Contact</a>
                {(user.email === undefined) ? <a href ="/login" className='list-item list-offering-item-bot'>Login</a> : <div className='list-item list-offering-item-bot' onClick={signOutFunc}>Logout</div> }
            </nav>

            <div className='heading-course-page'>

            <div className='course-page-title'>
                <p className='course-heading-title'>Project Msimbo</p>
                <p className='course-short-desc'>Everything you need to learn to code, on a single page.</p>
                {status && (status==="nahh_bro" ? 

                    <div className='buy-course'>
                        <a href ="/payments">
                            <p style={{color:"white"}} className='buy-the-course'>Buy the course</p>
                        </a>

                    </div>

                    :
                    <div className='buy-course'>
                        <p style={{color:"white"}} className='buy-the-course'>Enjoy the course</p>
                    </div>

                )}
            </div>


            </div>


        </section>

        <section className="course-landing">

            <div className='course-progress-introduction'>

                <div className='course-landing-progress'>
                    <div className='scrollable'>
                        <div className='progress-certificate'>
                            <TbCertificate className='certificate-icon-course'/>
                        </div>
                        <p className='course-progress-cert'>Certificate</p>
                        <div className='cource-circular-progress' style={conicstyle}>
                            <p className='percentage-done'>{getNew()}%</p>
                        </div>

                        <p className='course-progress-quizzes'>Quizzes completed</p>
                        <p className='progress-quizzes-tally'><strong><em>{userQuizTot.quiz? `${userQuizTot.quiz.length}/${Tquiz}` : "Loading"}</em></strong></p>

                        <p className='course-progress-videos'>Videos Watched</p>
                        <p className='progress-videos-tally'><strong><em>{userVidTot.videos ? `${userVidTot.videos.length}/${Tvids}` : "Loading..."}</em></strong></p>

                        <div className='course-get-progress-cert'>
                            <p className='get-cert'>Get Certifictae</p>
                        </div>

                    </div>


                </div>

                <div className='course-modules'>
                    <div className='course-module-intro'>
                        <p className='course-module-intro-title'>1. Introduction</p>
                        {status && (status == "nahh_bro" ? 
                        <p className='course-module-intro-desc'>Project Msimbo offers a guide for learning programming and machine learning using Google Colab. This approach allows anyone to learn programming in Python, even without a laptop!l</p>
                        :
                        <p className='course-module-intro-desc'>Project Msimbo offers a guide for learning programming and machine learning using Google Colab. This approach allows anyone to learn programming in Python, even without a laptop!l<br/><a id="course-book-highlight" href="http://do1.dr-chuck.com/pythonlearn/EN_us/pythonlearn.pdf">Link to course textbook!</a></p>
                        )}
                        <div id="troublesome-vid" className='course-module-intro-img'>
                            {/* <img src={Wom}/> */}
                            <iframe style={{borderRadius:"10px"}} width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/XUX7XQl2Y7g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                
                    {modules.filter(mod => mod.tl !== "Project 3").map((module,i) =>(
                        <div className='course-module-oop'>                        
                            {/* <p className='course-module-oop-title'>2. Object Oriented Programming</p> */}
                            <p className='course-module-oop-title'>{`${module.mindex}. ${module.tl}`}</p>
                            <div className='module-info'>

                                <div className='module-time module-in-line'>
                                    <div className='module-time-icon'>
                                        <BiTimeFive className='module-react-icon'/>
                                    </div>
                                    <p className='module-text'>
                                        <strong> Videos: {`${module.tvids}`} Videos</strong>
                                    </p>
                                </div>


                                <div className='module-time module-in-line'>
                                    <div className='module-time-icon'>
                                        <BiTimeFive className='module-react-icon'/>
                                    </div>
                                    <p className='module-text'>
                                        <strong> Time: {`${module.tvids}`} Hours</strong>
                                    </p>
                                </div>


                                <div className='module-time module-in-line'>
                                    <div className='module-time-icon'>
                                        <BiTimeFive className='module-react-icon'/>
                                    </div>
                                    <p className='module-text'>
                                        <strong> {`${module.diff}`}</strong>
                                    </p>
                                </div>

                            </div>
                            <p className='module-description'>{`${module.dsc}`}</p>
                            
                            <div className='module-start-lessons'>
                                <div className='left-start'>
                                    <MdOutlineVideoLibrary className='left-start-icon'/>
                                    <div className='left-start-progression'>
                                        <p><strong>Lessons</strong></p>
                                        <div className='lesson-progression'>
                                            <div className='lesson-progress-bar'>
                                                <div style={getStyleForLessBar(i)}>
                                                </div>
                                            </div>
                                            <p className='lesson-progress-percent'>{getPercBarForVids(i)}%</p>
                                            {/* <p className='lesson-progress-percent'>30%</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='right-start'>
                                    <a href={`${module.mlink}`}>
                                        <div className='right-start-btn'>
                                            <p className='right-start-text'>Start</p>
                                            <AiOutlineArrowRight/>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='module-start-lessons'>
                                <div className='left-start'>
                                    <AiOutlineUnorderedList className='left-start-icon'/>
                                    <div className='left-start-progression'>
                                        <p><strong>Assignments</strong></p>
                                        <div className='lesson-progression'>
                                            <div className='lesson-progress-bar'>
                                            <div style={getStyleForQuizBar(i)}></div>
                                            </div>
                                            <p className='lesson-progress-percent'>{getPercBarForQuiz(i)}%</p>
                                            {/* <p className='lesson-progress-percent'>20%</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='right-start'>
                                    <a href={`${module.mlink}`}>
                                        <div className='right-start-btn'>
                                            <p className='right-start-text'>Start</p>
                                            <AiOutlineArrowRight/>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            
                        </div>
                    ))}




      
                    
                </div>
                
            </div> 



        </section>




    </div>
  )
}

export default Course