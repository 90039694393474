import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app'
import './quiz.css'
import {db} from './firebase'
import {useParams} from 'react-router-dom'


function Quiz({user}) {
        
    const {quizModId,quizRoomId} = useParams();
    const [quiz,setQuiz] = useState({})
    const [currentQuizPage,setCurrentQuizPage] = useState("1")
    
    const [dataLength,setDataLength] = useState(-1)
    const[score,setScore] = useState(1)
    const[wrong,setWrong] = useState(1)
    
    const dataLength1 = dataLength-1;

    
    //  //Below code was developed for ADMIN panel!!!
    
    //  useEffect(()=>{
    //     someObjs.map((item,id) =>{
    //         db.collection('quiz').doc("PROG_FUND").collection("PROG_FUND_1").doc((id+1).toString()).set({
    
    //             question:item.question,
    //             id:item.id,
    //             ans_map:item.ans_map,
    //             correct:item.correct,
                
    
    //         }, (error) => {
    //             if (error) {
    //                 console.log(error)
    //             }
    //             });
    
    //     })
            
    //     },[])


    useEffect(() =>{
        db.collection('quiz').doc(quizModId).collection(quizRoomId).doc(currentQuizPage).onSnapshot(snapshot => {
            setQuiz({
                ans: snapshot.data().answers,
                question: snapshot.data().question,
                id: snapshot.data().id,
                ans_map: snapshot.data().ans_map,
                correct: snapshot.data().correct,
                image:snapshot.data().src
                
            })

    });

    },[currentQuizPage])




    useEffect(() =>{
        db.collection('quiz').doc(quizModId).collection(quizRoomId).onSnapshot(snapshot =>{
            setDataLength(snapshot.size)
        })
    },[dataLength])

   

    function addQizIds_Total(quizID,roomID){
        db.collection("users_2").doc(user.email).collection("total").doc("quizes").update({
            qlst: firebase.firestore.FieldValue.arrayUnion(quizID)
        }
        );

        db.collection("users_2").doc(user.email).collection("modules").doc(roomID).update({
            quizes: firebase.firestore.FieldValue.arrayUnion(quizID),
        }
        );
    };



    useEffect(()=>{

        let cWrong = wrong-1
        let percent = ((dataLength1)-cWrong)/dataLength1
        if ( (currentQuizPage == (dataLength1+1)) && (percent >= 0.5)){

            addQizIds_Total(quizRoomId,quizModId);

        }

    },[currentQuizPage])

    const checkIfRigth = (indi) =>{

        if (indi === quiz.correct){

            if (score>0){
              //  console.log("Score: "+score)
                setScore(score+1)
            }
            //console.log("Score has been incremented ")
            // console.log("total data:" +dataLength1)
            let quizNum =  parseInt(currentQuizPage);
            if (quizNum < dataLength1+1){
                quizNum ++;
                setCurrentQuizPage(quizNum.toString());
            }
            
            if ((quizNum-1) === dataLength1){
                console.log("Great Job")
            }

        }else{
            
            
            // console.log("Wrong: "+wrong)
            if(wrong>0){
                    setWrong(wrong+1)
                    // console.log("Wrong has been incremented")
                }
            
                let quizNum =  parseInt(currentQuizPage);
            if (quizNum < dataLength1+1){
                quizNum ++;
                setCurrentQuizPage(quizNum.toString());
            }
            alert("Wrong answer")
        }

        if(currentQuizPage === dataLength1){
            // console.log("You got "+(dataLength1-(wrong-1))+"  corrent!");
 

        }
    
    }

  return (
    <div className='"quiz_page'>
        <div className='quiz_page_colour'>
            { ((currentQuizPage < (dataLength1+1))) ? 

                <div className='quiz_card'>
                    <div className='quiz_desc'>                   
                        {quiz.id ? <>
                            <p className='current_quiz_number'>{`Quiz: ${quiz.id}/${dataLength1}`}</p>
                            {
                            (quiz.image) ? 
                           <div className='quiz_image'>
                               <p className='current_quiz_quest'>What is the output of the following code?</p>
                                <div className='quiz-image-div'>
                                    <img className='quiz-image-itself' src={`${quiz.image}`}></img>
                                </div>
                            </div>
                            :
                            <p className='current_quiz_quest'>{`${ quiz.question? quiz.question: "Loading..." }`}</p> 
 
    
                            }
                            </>
                            : 
                            <p className='current_quiz_loading_data'></p>}
                       
                    </div>
                    <div className='quiz_content'>
                        { quiz.ans_map ? Object.keys(quiz.ans_map).map(answer => (
                            <div className='answer_container'>
                                <div onClick={() => { checkIfRigth(answer)}} className="quiz_answer">
                                    {quiz.ans_map[answer]}
                                </div>
                            </div>
                        ) ) : console.log("no quiz")}
    
                    </div>
                </div>     
:
            <div className='quiz_card'>
                <div className='quiz_desc'>                 
                    {(dataLength1 !== -2) ? 
                    <>
                        <p className='current_quiz_number'>You have completed the quiz.<br/>{`Your score is: ${dataLength1-(wrong-1)}/${dataLength1}`}</p>
                        <p className='current_quiz_number'><a href="/course" className='quiz-to-vids'>Return to Video Catalogue.</a></p>
                    </>
                    
                    :
                    
                    <p className='current_quiz_number'>Loading Questions...</p>

                    }

                 </div>

            </div>
            
                
            
            }

        </div>
    </div>
  )
}


export default Quiz