import React from 'react'
import {useEffect,useState}from 'react'
import './hero.css'
import Logo from './web2.jpg';
import Logo2 from './web1.jpg';
import Logo3 from './web3.jpg';
import Logo4 from './bettercert.png';
import { GiBookCover } from "react-icons/gi"
import { CgWebsite } from "react-icons/cg"
import { FaRegListAlt } from "react-icons/fa"
import { BsArrowRight } from "react-icons/bs"
import { GiHamburgerMenu } from "react-icons/gi"
import { ImCross } from "react-icons/im"
import { db } from './firebase';


function Hero({user, signOut}) {

  const [open,setOpen] = useState("closed");

  function bringMenu(){
    setOpen("open")
  }

  function closeMenu(){
    setOpen("closed")
  }

  const sofuncs = () => {
    if(user.email){
        signOut()
    }else{
        window.location.replace("/login")
    }
}

// ADMIN CODE
//  useEffect(()=>{
//       lessAscend.map(item =>{
//           db.collection('lessons').doc("ML").collection("lesson_ml").doc(item.lindex.toString()).set({
  
//               ltitle:item.ltitle,
//               ldesc:item.ldesc,
//               urlvid:item.urlvid,
//               idvid:item.idvid,
//               lmains:item.lmains,
//               qlink:item.qlink,
//               qid:item.qid,
//               lindex:item.lindex,
              
  
//           }, (error) => {
//               if (error) {
//                   console.log(error)
//               }
//               });
  
//       })
          
//       },[])


  return (
    <div className="App">
      <section className="home-landing"> 

        <div className='msimbo-title'>
          <a  className='msimbo-title-link' href='/'>Project Msimbo</a>
          <GiHamburgerMenu className='ham_menu' onClick={bringMenu}/>
        </div>  
          <nav className={`offerings ${open}`}>
            <ImCross className='close-phone-menu' onClick={closeMenu}/>
            <a className='list-item list-offering-item-top' style={{textDecorationLine:"underline"}}>Home</a>
            <a className='list-item' href='/course'>Course</a>
            <a className='list-item' href='/tiqohome'>Tiqo</a>
            {/* <a className='list-item'>Pricing</a> */}
            <a href="https://briangitaukr.web.app/" className='list-item'>Contact</a>
            {( user.email === undefined) ? <a href ="/login" className='list-item list-offering-item-bot'>Login</a> : <a className='list-item list-offering-item-bot' onClick={sofuncs}>Logout</a> }
          </nav>

        <div className='heading-main-image'>

          <div className='heading-title'>
            <p className='msimbo-heading-title'>Project Msimbo</p>
            <p className='msimbo-short-desc'>Learn the skills of tomorrow</p>
            <div className='start-now'>
            <a href='/course'><p className='start-content'> Start Now</p></a>
            </div>
          </div>

          <div className='main-image'>
            <img src={Logo} />
          </div>

        </div>


      </section>


      <section className="home-msimbo">

        <p className='what-is-msimbo'>What is Project Msimbo</p>

        <div className='offerings-container'>
        
        <div className='msimbo-offerings'>
          <div className='offering-image'><GiBookCover id="offering-img"/></div>
          <div className='offering-title'><p className='offering-title-custom'>Comprehensive Curriculum</p></div>
          <div className='offering-desc'><p className='offering-desc-custom'>A curriculum that is designed to take you from novice to intermediate programmer in a few weeks.</p></div>
        </div>

        <div className='msimbo-offerings'>
          <div className='offering-image'><CgWebsite id="offering-img"/></div>
          <div className='offering-title'><p className='offering-title-custom'>Multiple Projects</p></div>
          <div className='offering-desc'><p className='offering-desc-custom'> Gain access to a wide catalogue of programming projects and quizzes that will embellish your portfolio.</p></div>
        </div>

        <div className='msimbo-offerings'>
          <div className='offering-image'><FaRegListAlt id="offering-img"/></div>
          <div className='offering-title'><p className='offering-title-custom'> Platform Independent</p></div>
          <div className='offering-desc'><p className='offering-desc-custom'> The website is developed for both desktop and mobile environments, meaning your education is not limited by the device you have.</p></div>
        </div>

        </div>

      </section>

      <section className="home-curriculum">    

        <div className='curriculum-info-img'>

          <div className='curric-info'>
            <p className='curric-title'>Comprehensive Curriculum</p>
            <p className='curric-desc'>This course was constructed to take you from novice to guru with a curriculum that enables anyone (independent of where they are on their programming journey) to swiftly pick up the python programming language.</p>

            <a href='/course'>
              <div className='explore-curric fade-slight'>
                <p className='explore-curric-text'><a>Explore the curriculum</a></p>
                <BsArrowRight/>
              </div>
            </a>
          </div>

          <div className='curriculum-img'><img src={Logo2}/></div>

        </div>


      </section>


      <section className="home-project">    

        <div className='project-info-lst'>

        <div className='project-lst'>
          <div className='lst-padding'><p className='lst-content'>Magic 8 Ball</p></div>
          <div className='lst-padding'><p className='lst-content'>House Price Prediction with ML</p></div>
          <div className='lst-padding'><p className='lst-content'>Web Bot with Automated Emailing</p></div>
          <div className='lst-padding'><p className='lst-content'>YouTube Video Creator</p></div>
        </div>

        <div className='project-info'>
          <p className='project-title'>Practical Programming Projects</p>
          <p className='project-desc'>Cognisant of the power of projects to land people their internship or get them accepted into prestigious universities, this course is full of projects and quizzes designed to leave your employers or friends speechless.</p>
        
        <a href='/course'>
          <div className='try-free-proj fade-slight ppp-adjust'>
            <p className='free-proj-text'><a>Try a Project</a></p>
            <BsArrowRight/>
          </div>
        </a>

        </div>
        
        </div>


      </section>

      <section className="home-colab">      

        <div className='curriculum-info-img'>

        <div className='curric-info'>
          <p className='curric-title'>Platform Independent</p>
          <p className='curric-desc'>I built this website to be user-friendly on both smartphones and computers. This is coupled by the incorporation of Google Colab, a website that will allows users to write code on non-desktop environments. Whether you're on a phone or a computer, the quality of education you receive will remain steadfast.</p>
          <a href='/course'>
            <div className='explore-curric fade-slight'>
              <p className='explore-curric-text'><a>Explore the curriculum</a></p>
              <BsArrowRight/>
            </div>
          </a>

        </div>

        <div className='colab-img'><img src={Logo3}/></div>

        </div>

      </section>

      <section className="home-cert">  

        <div className='whole-cert'>

          <div className='cert-info'>
            <p className='cert-title'>Comprehensive Curriculum</p>
            <p className='cert-desc'>Show off your new programming skills!</p>
          </div>

          <div className='cert-img'><img src={Logo4}/></div>

          <a href='/course'>
            <div className='cert-start-course fade-slight'>
            <p className='cert-start-course-text'><a>Begin your journey</a></p>
            <BsArrowRight/>
            </div>
          </a>

        </div>

      </section>
    </div>
  )
}

export default Hero