import React from 'react';
import "./cozy.css";
import codeImg  from './codeG-2.png';
import plant  from './pot_plant.png';
import {useEffect,useState,useRef}from 'react'


function Cozyman() {
    const messageRef = useRef();

    const [data,setData] = useState(null);
    const [chats,setChats] =useState(["Hey there! Got any questions for me today?",
        "What are some of the advantages and disadvantages of using logistic regression for classification compared to other methods such as linear regression, decision trees, or support vector machines? "]);

    const av1 ="https://avatoon.me/wp-content/uploads/2021/09/Cartoon-Pic-Ideas-for-DP-Profile-06-768x766.png"
    const av2 ="https://avatoon.me/wp-content/uploads/2021/09/Cartoon-Pic-Ideas-for-DP-Profile-03-768x768.png"

    function fetchDataSwahili() {
        setChats(chats => chats.concat(data))
        fetch(`https://cors-anywhere.herokuapp.com/https://long-gold-chick-belt.cyclic.app/twllm/${data}`)
          .then(response => response.json())
          .then(data => {
            setChats(chats => chats.concat(data.text));
          })
          .catch(error => {
            console.error(error);
          });
          setData("")
      }

      useEffect(() => {
        if (messageRef.current) {
          messageRef.current.scrollIntoView(
            {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest'
            })
        }
      })
    

      function fetchDataEnglish() {
        setChats(chats => chats.concat(data))
        fetch(`https://cors-anywhere.herokuapp.com/https://long-gold-chick-belt.cyclic.app/directTranslate/${data}`)
          .then(response => response.json())
          .then(data => {
            setChats(chats => chats.concat(data.data));
          })
          .catch(error => {
            console.error(error);
          });
          setData("")
      }

    // useEffect(() => {
    //     fetch('https://cors-anywhere.herokuapp.com/https://long-gold-chick-belt.cyclic.app/translate/directTranslate/false', {
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //       },
    //     })
    //       .then(response => response.json())
    //       .then(data => {
    //         console.log(data.text);
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    //   }, []);
    


  return (
    <div className='main-cozy-page'>
        <div className='main-cozy-container'>
        <img className='cozy-plant' src={plant}/>
            <div className='cozy-man-photo'>
                <img className='cozy-man-photo-img' src={codeImg}/>
            </div>

            <div className='chat-bot-area'>
                <div className='cozzy-man-title'>
                    <p className='cozy-man-title-text'>Orient-W-Research: T5 X Pinecone</p>
                </div>
                <div className='cozy-whole-text-area'>
                    
                    {
                        chats.map((chat,index) =>(
                            <div className='cozy-first-message-area'>
                                
                                <img className='ai-avatar' src={(index%2 ===1 ) ? av2 : av1}/>
                                <p className='cozy-first-message'>{chat}</p>
                    </      div>
                        ))
                    }
                    <div ref={messageRef}></div>
                </div>
                    <input value={data} onChange={e => setData(e.target.value)} className='cozy-text-input' placeholder='Ask something'></input>
                <div className='switch-langauge'>
                    <p className='choose-answer'>Answer in:</p>
                    <div>

                    <button onClick={fetchDataSwahili} className='swahili-cozy cozy-btton'>
                        Swahili
                    </button>
                    <button onClick={fetchDataEnglish} className='english-cozy cozy-btton'>
                        English
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cozyman