import React, {useState, useEffect} from 'react'
import "./TiqoHomePage.css"
import {db} from './firebase'
import { BiLockAlt } from "react-icons/bi"
import { HiPencilAlt } from "react-icons/hi"



function TiqoHomePage({user, signOut}) {
    const [quiz,setQuiz] = useState({})
    const [currentQuiznum,setCurrentQuiznum] = useState(1)
    const [documents, setDocuments] = useState([]);
    const [vidsTrans,setVidsTrans] = useState(88)


    useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).onSnapshot(snapshot => {
            setVidsTrans(snapshot.data().vids)
        
        //console.log("snapshot data: "+snapshot.data().vids)
    
    });
    
    },[user])


    useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).collection("youtube").onSnapshot(snapshot => {
            const documents = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setDocuments(documents); 
                        

    });

    },[user])

    function trimString(str) {
        if (str.length > 52) {
          str = str.substr(0, 52) + "...";
        }
        return str;
      }
    
      const sofuncs = () => {
        if(user.email){
            signOut()
        }else{
            window.location.replace("/login")
        }
    }



  return (
    <div className='tiqo-homeback'>
        <div className='tiqo-home-center-div'>

            <div className='tiqo_options'>
                <div className='tiqo_options_text'>
                    <p className='tiqo_options_acc_text coloured_tiqo_opt'> TIQO - Youtube (Beta)</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - PDF</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - Slides</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO - Websites</p>
                    <br/>
                    <p className='tiqo_options_acc_text'><BiLockAlt/>TIQO W/ ChatGPT</p>
                    <br/>
                    <a href='/'><p className='tiqo_options_acc_text_small'>Project Msimbo</p></a>
                    <br/>
                    <p onClick={sofuncs} className='tiqo_options_acc_text_small logout_tiqo'>Log Out</p>
                </div>
            </div>

            <div className='tiqo_collection'>
                    <div className='add_quiz'>
                        <div className='tiqo-quiz-trans'>
                            {(vidsTrans !== 88) && <p className='tiqo-quiz-cdown'>Daily video allocation: {`${vidsTrans}`}/7</p>}
                        </div>
            
                        <div className='tiqo-add-quiz-entire-div'>
                        <a href='/tiqolnk'>
                            <div className='adjust-tiqo-add'>
                                <div className='add_log'>+ </div>
                                <span className='add_tiqo'>Add a new quiz</span>

                            </div>

                        </a>
                        </div>
                    </div>



                <div className='collection_box'>
                    {documents.length>0 ? documents.map(doc =>(

                        doc.status.toLowerCase() !== "pending ..." 
                        
                        ? 

                            <div className='tiqo_card'>
                                
                                <img className='the' src={`${(doc.thumb_url !== null) ? doc.thumb_url : "https://thumbs.dreamstime.com/b/e-learning-education-concept-flat-vector-illustration-people-accessing-knowledge-online-library-online-education-books-177859305.jpg"}`}></img>

                                <p className='tiqo_card_inf'>Title: {`${trimString(doc.title)}`}</p>
                                <p className='tiqo_card_inf'>Status: {`${doc.status}`}</p>

                                <div className='quiz-flas-tiqo'>
                                    <span className='quiz-flas-tiqo-btn'> <a href={`tiqoflash/${doc.id}`}>Flash cards</a></span>
                                    <span className='quiz-flas-tiqo-btn'><a href={`tiqoquiz/${doc.id}`}>Quiz</a></span>
                                    <span className='quiz-flas-tiqo-btn'><a href={`tiqoedit/${doc.id}`}><HiPencilAlt/></a></span>
                                </div>
                            </div>
                    
                        :

                        <div className='tiqo_card'>
                            <img className='the' src={`${doc.thumb_url}`}></img>

                            <p className='tiqo_card_inf'>Title: {`${trimString(doc.title)}`}</p>
                            <p className='tiqo_card_inf'>Status: {`${doc.status}`}</p>
                        </div>

                    

                    )) : 
                    
                    <div className='empyt-tiqo-lst'>
                     <p className='no-videos-tiqo'><em>Things are awfully quiet around here, try adding a video with "Add a new quiz"</em></p>
                    
                    </div>}


                </div>


            </div>

        </div>

    </div>
  )
}

export default TiqoHomePage