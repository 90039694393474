import Hero from './hero'
import Course from './Course';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ModuleOne from './ModuleOne';
import Login from './Login';
import {db, fire} from './firebase';
import {useEffect,useState}from 'react'
import Quiz from './Quiz';
import { useStateValue } from './StateProvider';
import { actionTypes } from './reducer';
import 'firebase/auth'
import Payment from './Payment';
import Cozyman from './Cozyman';
import TiqoFlash from './TiqoFlash';
import Tiqo from './TiqoFlash';
import TiqoHomePage from './TiqoHomePage';
import TiqoLnk from './TiqoLnk';
import Tiqoquiz from './Tiqoquiz';
import TiqoEdit from './TiqoEdit';
import DummyTiqoHome from './DummyTiqoHome';
import DummyTiqoFlash from './DummyTiqoFlash';


function App() {

  const [emailsInDb,setEmailsInDb] = useState([]);
  const [user,setUser] = useState("true");
  const [email,setEmail] = useState('');
  const [pass,setPass] = useState('');
  const [rePass,setRePass] = useState('');
  const [emailError,setEmailError] = useState('');
  const [passError,setPassError] = useState('');
  const [hasAccount,setHasAccount] = useState(false);


  const clearInputs = () =>{
    setEmail('');
    setPass('');
    setRePass('');
  }

  const clearError = () => {
    setPassError('');
    setEmailError('');
  }

  useEffect(()=>{
    let mails=[]
    db.collection("users_2").onSnapshot(snapshot => {
      snapshot.docs.map(doc => {
        mails.push(doc.id)
      })
    })
    setEmailsInDb(mails)
  },[])

  // function sendVerification(){
  //   var user = fire.auth().currentUser;

  //   user.sendEmailVerification().then(function(){

  //   }).catch(function(err){
  //     console.log(err)
  //   })
  // }


  function setUpUser(email){

//    if (!(emailsInDb.includes(email))){
//    nahh_bro


      const modules =["ML","OOP","PROG_FUND","PROG_FUND2","PROJ","PROJ2","PROJ3",]

    
      const dbref = db.collection("users_2").doc(email)

      dbref.set({
        status:"nahh_bro"
      })

    
      modules.map(module => {
        dbref.collection("modules").doc(module).set({
          quizes:[],
          videos:[],
        })
      })
  
      dbref.collection("total").doc("quizes").set({
          qlst:[],
        })
  
      dbref.collection("total").doc("videos").set({
        vlst:[],
      })

      db.collection("user_tiqo").doc(email).set({
        vids:0
      })

      db.collection("user_tiqo").doc(email).set({
        opentrans:0
      },{merge:true})


 //   }

  }


  async function handleLogin() {
    clearError();
    clearError();
    await fire
      .auth()
      .signInWithEmailAndPassword(email,pass)
      .then(function() {
        window.location.replace("/");
      }).catch(error => {
        switch (error.code){
          case 'auth/invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            setEmailError(error.message);
            break;
          case 'auth/wrong-password':
            setPassError(error.message);
            break;
        }
      })
      // if(user){
      //   window.location.replace('/')
      // }
    }


    


  async function handleSignup (){
    if(pass===rePass){
      var emailSmall = email.toLowerCase();
      clearError();
      // let error_ = false
      await fire
      .auth()
      .createUserWithEmailAndPassword(emailSmall,pass)
      .then(function() {
        
        //var user = fire.auth().currentUser;
        setUpUser(emailSmall);
        db.collection("users_2_waste").doc("new").set({
          user:"_"
        })
        
        .then(function(){
          window.location.replace('/')
        })
        
      })
      .catch(error => {
        // error_=true
        switch (error.code){
          case 'auth/email-already-in-use':
          case 'auth/invalid-email':
            setEmailError(error.message);
            clearInputs();
            break;
          case 'auth/weak-password':
            setPassError(error.message);
            clearInputs();
            break;
        }
      })
    }else{
      setPassError("Passwords don't match");
      clearInputs();
    }

  }


  const handleSignOut = () => {
    fire.auth().signOut()
  }

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if(user){
        clearInputs();
        setUser(user);
        // if( window.location.href === 'https://project-msimbo.cf/login' || window.location.href === 'https://project-msimbo.web.app/login' || window.location.href === 'http://localhost:3000/login'){
        //   window.location.replace('/')
        //yess_fam
        //}
      }else{
        setUser('');
      }
    })
  }

  useEffect(()=>{
    authListener();
  },[user,hasAccount])

  const site_paths = [["/course",<Course user={user} signOut={handleSignOut}/>],
                      ["/course/:courseID/:lessonID",<ModuleOne user={user} signOut={handleSignOut}/>],
                      ["/quiz/:quizModId/:quizRoomId",<Quiz user={user} signOut={handleSignOut}/>],
                      ["/tiqoflash/:tiqolnkId",<TiqoFlash user={user}/>],
                      //["/tiqohome",<TiqoHomePage user={user} signOut={handleSignOut}/>],
                      ["/tiqolnk",<TiqoLnk user={user}/>],
                      ["/tiqoquiz/:tiqoQuizId",<Tiqoquiz user={user}/>]]



  return (

      <Router>

        <Routes>

        <Route path="/" element={<Hero user={user} signOut={handleSignOut}/>} />
        <Route path="/payments" element={<Payment/>} />
        <Route path="/cozyman" element={<Cozyman/>} />
        <Route path="/tiqoexflash" element={<DummyTiqoFlash/>} />
        <Route path="/tiqoedit/:editId" element={<TiqoEdit user={user}/>} />

          {site_paths.map(path => (
          user ? 
            <Route path={`${path[0]}`} element={path[1]}/>
          :
          <Route path={`${path[0]}`} element={<Login email={email} setEmail={setEmail} handleSignup={handleSignup} hasAccount={hasAccount} setHasAccount={setHasAccount} pass={pass} rePass={rePass} setRePass={setRePass} handleLogin={handleLogin} emailError={emailError} passError={passError} setPass={setPass}/>} />
          
           ))}

          {user? 
              <Route path="/tiqohome" element={<TiqoHomePage user={user} signOut={handleSignOut}/>} />            
          :

          <Route path="/tiqohome" element={<DummyTiqoHome/>} />
          
        }
            
          <Route path="/login" element={<Login email={email} setEmail={setEmail} handleSignup={handleSignup} hasAccount={hasAccount} setHasAccount={setHasAccount} pass={pass} rePass={rePass} setRePass={setRePass} handleLogin={handleLogin} emailError={emailError} passError={passError} setPass={setPass}/>} />


        </Routes>
      </Router>
    );
}

export default App;
