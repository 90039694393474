import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app'
import './Tiqoquiz.css'
import {db} from './firebase'
import {useParams} from 'react-router-dom'
import { BiMessageSquareDetail } from "react-icons/bi"



function Tiqoquiz({user}) {
        
    const {tiqoQuizId} = useParams();
    const [inputValue, setInputValue] = useState('');
    const [quiz,setQuiz] = useState({})
    const [currentQuiznum,setCurrentQuiznum] = useState(1)
    
    // if(tiqoQuizId){

    //     console.log(tiqoQuizId)
    // }
    const dataLength = quiz.question ? quiz.question.length  :0 
    
    const quizesLst = quiz? quiz.question : []

    const [answers,setAnswers]=useState([])
    const [remButton,setRemButotn] = useState(false)
    const [botFeedb,setBotFeedb]=useState("")

    const [renderLink,setRenderLink] = useState("")

    
    
    
    useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqoQuizId).onSnapshot(snapshot => {

            setQuiz({
                question: snapshot.data().questions,
                botfb: snapshot.data().botfeedback
                
            })
            
        });
       // console.log(answers)

        deleteStudAns()
        deleteBotFeedback();
        
    },[user])

    useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqoQuizId).onSnapshot(snapshot => {

            setBotFeedb(
                snapshot.data().botfeedback
                
            )
            
        });
        
    },[user,currentQuiznum])
    
    
    useEffect(()=>{
        db.collection('render').doc("renderPath").onSnapshot(snapshot => {

            setRenderLink(
                snapshot.data().link
                
            )
            
            console.log(renderLink)
        });
    },[user])

    console.log("hi")
    

    const handleSubmit = (event) => {
        event.preventDefault();
        setRemButotn(true);
        const apiUrl = `https://tiqo-app-demo.onrender.com/teach/${tiqoQuizId}/${user.email}`;
        fetch(apiUrl,{mode: "cors"});
        
      };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        //console.log(inputValue);
      };
    
    //  console.log((currentQuiznum-1) === dataLength)

      const incrementQz = () => {
          if ((currentQuiznum-1) < dataLength){
            //setAnswers([...answers,inputValue])
            updateDocument(inputValue)
            //updateDocument(inputValue)

            setInputValue("")
			setCurrentQuiznum(currentQuiznum+1);
		}
        else if((currentQuiznum-1) === dataLength){
            //handleSubmit()
            setAnswers([...answers,inputValue])

            
          //  console.log("yooo")
        }
	}

    //console.log(currentQuiznum-1 === dataLength)

    function deleteStudAns() {
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqoQuizId).set(
            {"studanswers":[]},{merge:true}
        )
    }

    function deleteBotFeedback() {
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqoQuizId).set(
            {"botfeedback":""},{merge:true}
        )
    }

    function updateDocument(content) {
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqoQuizId).update({
            studanswers: firebase.firestore.FieldValue.arrayUnion(content)
        }
        );
        }


    function addQizIds_Total(quizID,roomID){
        db.collection("users_2").doc(user.email).collection("total").doc("quizes").update({
            qlst: firebase.firestore.FieldValue.arrayUnion(quizID)
        }
        );

        db.collection("users_2").doc(user.email).collection("modules").doc(roomID).update({
            quizes: firebase.firestore.FieldValue.arrayUnion(quizID),
        }
        );
    };

  return (
    <div className='"quiz_page'>
        <div className='quiz_page_colour tiqo_quiz_colour'>
            { (currentQuiznum-1 !== dataLength) 
            ? 
                <div id="tiqo_quiz_card" className='quiz_card' >
                    <div className='quiz_desc'>                   
                        {quizesLst  ? 
                            <>
                                <p className='current_quiz_number'>{`Quiz: ${currentQuiznum}/${dataLength}`}</p>
                                <p className='current_quiz_quest'>{`${quizesLst[currentQuiznum-1]}`}</p> 
    
                            </>
                        : 
                        
                        <p className='current_quiz_loading_data'></p>
                        
                        }
                       
                    </div>
                    <div className='quiz_content tiqo-quiz-cont'>
                        <div>
                            <input type="text" value={inputValue} onChange={handleInputChange} className='tiqo-quiz-ansinp' placeholder='Enter answer'/>
                        </div>

                        <div>
                            <button onClick={incrementQz} className='next-tiqo-quiz'>Next</button>
                        </div>

                    </div>
                </div>     
            :

                <div className='quiz_card' id="colour-tiqo-quiz">
                    <div className='quiz_desc'>    

                        {(((currentQuiznum-1) === dataLength)&&(botFeedb)) 
                        
                        ? 
                                <>
                                    {/* <p className='current_quiz_number'><span>You have completed the quiz.</span><br/>Advice from our bot:<br/> {quiz.botfb}</p> */}
                                    <p className='current_quiz_number'><p className='tiqo-congrats-quiz'>Congrats on completing the quiz!</p><br/><span className='advice-tiqo'>Advice from our bot:</span> <br/><br/></p>
                                    <div  className='tiqo-advice-box'>
                                        <p>{`${botFeedb}`}</p>
                                    </div>
                                    <p className='tiqo-advice-disclaimer'>*This chat bot may not always be accurate. Take its advice with a grain of salt :)</p>
                                    <p className='current_quiz_number'><a href="/tiqohome" className='quiz-to-vids tiqo-quiz-tovids'>Return to Quiz Catalogue.</a></p>
                                </>
                        
                        :

                        (dataLength==0) ? 
                        
                            <div className='get_tiqo_resp'>
                                <p className='current_quiz_number'>Get questions...</p>
      
                            </div> 
                        :

                        (remButton === false) ? 
                            <div className='get_tiqo_resp'>
                                <p className='current_quiz_number '>Click the button to a response from our bot</p>
                                <button onClick={handleSubmit} className='get_response'>
                                    Get response
                                    <BiMessageSquareDetail className='robot-tiqo-resp'/>
                                </button>
                                    
                            </div> 
                        :
                            <div className='get_tiqo_resp'>
                                <p className='current_quiz_number '>Fetching response ...</p>
                            </div> 

                    }

                    </div>

                </div>
            
                
            
            }
        </div>
    </div>
  )
}


export default Tiqoquiz