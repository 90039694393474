import React, { useEffect } from 'react'
import './module.css'
import { GiHamburgerMenu } from "react-icons/gi"
import { FaArrowLeft, FaPassport } from "react-icons/fa"
import { FaArrowRight } from "react-icons/fa"
import { BiLockAlt } from "react-icons/bi"
import { BsCheckLg } from "react-icons/bs"
import { BsCodeSlash } from "react-icons/bs"
import { ImCross } from "react-icons/im"
import {useState} from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'


import bthumb from './bluredthumb.jpg'
import thumb from "./thumb.jpg"
import { db } from './firebase'



function ModuleOne({user, signOut}) {
    
    var { courseID,lessonID } = useParams();
    var userEmailSamll = user.email && user.email.toLowerCase();
    const [userTotals,setUserTotals] = useState({});
    const [status,setStatus] = useState("");
    const [userVidTot,setVidTot] = useState({});
    const [moduleQuizVids,setModuleQuizVids] = useState([0,0])
    const [userQuizTot,setUserQuizTot] = useState({});
    const [open,setOpen] = useState("closed");
    const [lessonContent,setLessonContent]= useState({})
    const [otherLessons,setOtherLessons]= useState([])


//    const lessons_quiz =     
    // CODE SHOULD BE USED TO CREATE USER FILEDS WHEN THEY LOG IN    
    
    // useEffect(()=>{
    //         const mlUpdate = [
    //             {
    //                 "ltitle": "K Nearest Neighbor",//important
	// 				"ldesc": "K nearest neighbors (KNN) is a simple yet effective machine learning algorithm that can be used for classification and regression tasks",//important
	// 				"urlvid": "https://www.youtube-nocookie.com/embed/wnseY8goQoc",
	// 				"idvid": "ml",
	// 				"lmains": "",
	// 				"qlink": "NA",
	// 				"qid": "NA",
	// 				"lindex": 4,
    //             }
              
    //         ];

    //         mlUpdate.map((item) =>{
    //               db.collection('lessons').doc("ML").collection("lesson_ml").doc((item.lindex).toString()).set({
    //                 idvid:item.idvid,
    //                 ldesc:item.ldesc,
    //                 lindex:item.lindex,
    //                 lmains:item.lmains,
    //                 ltitle:item.ltitle,
    //                 qid:item.qid,
    //                 qlink:item.qlink,
    //                 urlvid:item.urlvid,
          
    //               }, (error) => {
    //                   if (error) {
    //                       console.log(error)
    //                   }
    //                   });
          
    //           })
                  
    //           },[])

    // useEffect(()=>{
    //     db.collection("modules").doc(courseID).onSnapshot(snapshot => {
    //         setUserQuizVidTot([snapshot.data().tquiz,snapshot.data().tvids])
    //     })

    //     console.log()
    // },[])



    function getNumberOfVidsQuiz(lst,name){
        let count=0;
        name = name.toLowerCase()
        if (name === "prog_fund"){
            for (let i=0;i<lst.length;i++){
                let elem = lst[i].toLowerCase().split("_")
                let word = elem.slice(0,2).join("_")
                if (word === "prog_fund"){
                    count++;
                }
            }
        }else if(name === "prog_fund2"){
            for (let i=0;i<lst.length;i++){
                let elem = lst[i].toLowerCase().split("_")
                let word = elem.slice(0,2).join("_")
                if (word === "prog_fund2"){
                    count++;
                }
            }
        }
        
        
        else{
            for (let i=0;i<lst.length;i++){
                let elem = lst[i].split("_")[0].toLowerCase()
                if (elem === name){
                    count++;
                }
            }
        }
        return count;
    }

    useEffect(()=>{
        db.collection("users_2").doc(userEmailSamll).onSnapshot(snapshot => {
            setStatus(snapshot.data().status)
            console.log(snapshot.data().status)
        })

    },[status,lessonContent,userQuizTot])

    
    
    useEffect(()=>{
            db.collection("modules").doc(courseID).onSnapshot(snapshot => {
                setModuleQuizVids([snapshot.data().tquiz,snapshot.data().tvids])
            })
        },[])

    useEffect(()=>{
        db.collection("users_2").doc(userEmailSamll).collection("modules").doc(courseID)
        .onSnapshot(snapshot => {
            setUserTotals({
                quiz:snapshot.data().quizes,
                videos:snapshot.data().videos,
            })

        })
        if(typeof userTotals !== "undefined"){

        }
    },[lessonContent,userQuizTot,status])


    // Not dynamic
    useEffect(()=>{
        db.collection("users_2").doc(userEmailSamll).collection("total").doc("videos").onSnapshot(snapshot =>{
            setVidTot({
                videos:snapshot.data().vlst
            })
        })        

    },[])


    // Not dynamic
    useEffect(()=>{
        db.collection("users_2").doc(userEmailSamll).collection("total").doc("quizes").onSnapshot(snapshot =>{
            setUserQuizTot({
                quiz:snapshot.data().qlst
            })
        })        

    },[])


    useEffect(()=>{

        db.collection("lessons").doc(courseID).collection(`lesson_${courseID.toLocaleLowerCase()}`).orderBy("lindex","asc").get().then(
            data => {
                setOtherLessons(data.docs.map(doc => (
                        [[doc.data().lindex,doc.data().ltitle]]
                ))
                )
                
            }
        )
    },[])   

    useEffect(()=>{
        db.collection("lessons").doc(courseID).collection(`lesson_${courseID.toLocaleLowerCase()}`).doc(lessonID).onSnapshot(snapshot => {
            setLessonContent({
                vid_id:snapshot.data().idvid,
                ldesc:snapshot.data().ldesc,
                lindex:snapshot.data().lindex,
                lmains:snapshot.data().lmains,
                ltitle:snapshot.data().ltitle,
                qid:snapshot.data().qid,
                qlink:snapshot.data().qlink,
                urlvid:snapshot.data().urlvid,
            })
        })

    },[]);

    const excludedCourses = ["PROJ","PROJ2","PROJ3"];


    const getRightLst = (par,cond) =>{
        const part = par.filter(el => (el[0][0] === cond))
        return part[0][0]
    }

    function getQuizStat(){
        if(lessonContent && userTotals.quiz){

            if (userTotals.quiz.includes(lessonContent.qid.toUpperCase())){
                return <>
                    <BsCheckLg className='status-background'/>
                    <p className='quiz-status-text'>Quiz completed</p>
                </>
            }else{
                return <>
                    <ImCross className='status-background'/> 
                    <p className='quiz-status-text'>Quiz not completed</p>
                </>
            }
        }
    }
    function getVidStat(){
        //if( lessonContent  && userTotals){
        if((typeof userTotals.videos === "object") && (userTotals.videos !== "undefined") && (typeof lessonContent.vid_id === "string")){

            if (userTotals.videos.includes(lessonContent.vid_id)){
                return <div onClick={addOrRemVidId} className='watched-marker'><BsCheckLg/></div>
            }else{
                return  <div onClick={addOrRemVidId} className='watched-marker'></div>
            }

            }

            
        }
    
    
    // NOT DYNAMIC!!
    function addVidIds(vidID,modId){
        db.collection("users_2").doc(userEmailSamll).collection("modules").doc(modId).update({
            videos: firebase.firestore.FieldValue.arrayUnion(vidID)
        }
        )
        
    }

    // NOT DYNAMIC!!
    function remVidIds(vidID,modId){
        db.collection("users_2").doc(userEmailSamll).collection("modules").doc(modId).update({
            videos: firebase.firestore.FieldValue.arrayRemove(vidID)
        }
        )
    }

    // NOT DYNAMIC!!
    function addVidIds_Total(vidID){
        db.collection("users_2").doc(userEmailSamll).collection("total").doc("videos").update({
            vlst: firebase.firestore.FieldValue.arrayUnion(vidID)
        }
        )
    }

    // NOT DYNAMIC!!
    function remVidIds_Total(vidID){
        db.collection("users_2").doc(userEmailSamll).collection("total").doc("videos").update({
            vlst: firebase.firestore.FieldValue.arrayRemove(vidID)
        }
        )
    }

//    if(userTotals.videos){
        //console.log(lessonContent.vid_id)
    //    console.log(typeof userTotals.videos)
    //     console.log(userTotals.videos)
        // console.log("cond1:"+(typeof lessonContent.vid_id === "string").toString())
        // console.log("cond2: "+(typeof userTotals.videos === "object").toString())
  //  }

    function addOrRemVidId(){
        Total_addOrRemVidId();
        if((typeof userTotals.videos === "object") && (userTotals.videos !== "undefined") && (typeof lessonContent.vid_id === "string")){
            if (userTotals.videos.includes(lessonContent.vid_id)){
                remVidIds(lessonContent.vid_id,courseID)
            }else{
                addVidIds(lessonContent.vid_id,courseID)
            }
        }else{
            addVidIds(lessonContent.vid_id,courseID)

            
        }
    }

        function Total_addOrRemVidId(){
        if ((userTotals.videos !== "undefined") && (typeof lessonContent.vid_id === "string")){
            if (userTotals.videos.includes(lessonContent.vid_id)){
                remVidIds_Total(lessonContent.vid_id)
            }else{
                addVidIds_Total(lessonContent.vid_id)
            }
        }
    }

                    // (otherLessons.length> 0 && lessonContent.lindex & ((lessonContent.lindex -1) !== 0)) ?

    function giveUserQuizProg(){
        if ((userTotals.quiz !== undefined) && moduleQuizVids){
            return `${userTotals.quiz.length}/${moduleQuizVids[0]}`;
        } else{
            return "0/0";
        }
    }

    function giveUserVideoProg(){
        if (userTotals.videos !== undefined){
            return `${userTotals.videos.length}/${moduleQuizVids[1]}`;
        } else{
            return "0/0";
        }
    }
    
    function highlightLessonName(quiz,id){
        if(quiz && id){
            if (quiz === parseInt(id)){
                return "highlight-lesson-name"
            }else {
                return "dont-highlight"
            }
                
        }
    }

    function bringMenu(){
      setOpen("open")
    }
  
    function closeMenu(){
      setOpen("closed")
    }



  
    
    function getPercent(){
        let new_;
        if(userTotals.videos && moduleQuizVids){
            const Tvids = moduleQuizVids[1];
            const userCompletedVids= userTotals.videos.length;
            new_ = (userCompletedVids/Tvids)*100;
            new_ === 100 ? new_ = 100 : new_=new_.toPrecision(2)
            return new_
        }else{
            return 0
        }
    }

    let conicstyle ={
        margin:"auto",
        borderRadius:"50%",
        width:"100px",
        height:"100px",
        // background:"conic-gradient(#851A3D 110deg, #9FC7AA 0deg)",
        background:`conic-gradient(#851A3D ${(getPercent()/100)*360}deg, #9FC7AA 0deg)`,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    }

    function styleText(text){
        const cutoff=10;
        if (text.length >cutoff){
            return text.slice(0,cutoff)+" ...";
        }else{
            return text
        }
    }

    function spitModName(abv){
        //PROG_FUND , PROG_FUND2, OOP,PROJ ,PROJ 2,ML,PROJ3 
        if (abv ==="PROG_FUND"){
            return "Programming Fundamentals"
        }else if(abv === "PROG_FUND2"){
            return "Programming Fundamentals 2"
        }else if(abv === "OOP"){
            return "Object Oriented Programming"
            
        }else if(abv === "PROJ"){
            return "Project 1"
            
        }else if(abv === "PROJ2"){
            return "Project 2"
            
        }else if(abv === "ML"){
            return "Machine Learning"
            
        }else if(abv === "PROJ3"){
            return "Project 3"
            
        }
    }


  return (
    <div className='course'>
        <section className="home-landing"> 

            <div className='msimbo-title'>
                <a  className='msimbo-title-link' href='/'>Project msimbo</a>
                <GiHamburgerMenu className='ham_menu' onClick={bringMenu}/>
            </div>  

            <nav className={`offerings ${open}`}>
                <ImCross className='close-phone-menu' onClick={closeMenu}/>
                <a href="/" className='list-item list-offering-item-top'> Home</a>
                <a href="/course" className='list-item'>Course</a>
                {/* <a className='list-item'>Pricing</a> */}
                <a href="https://briangitaukr.web.app/" className='list-item list-offering-item-bot'>Contact</a>
            </nav>

            <div className='heading-course-page'>

            <div className='course-page-title'>
                <p className='course-heading-title module-header-title'>{spitModName(courseID)}</p>
                <p className='course-short-desc'>Everything you need to learn to code, on a single page.</p>
                {status && ( status==="nahh_bro" ? 

                    <div className='buy-course'>
                        <a href ="/payments">
                            <p style={{color:"white"}} className='buy-the-course'>Buy the course</p>
                        </a>

                    </div>
                    
                :
                    <div className='buy-course'>
                        <p style={{color:"white"}} className='buy-the-course'>Enjoy the course</p>
                    </div>

            )}
            </div>


            </div>


        </section>

        {status ? (<section className="course-landing">

            <div className='course-progress-introduction'>

                <div className='course-landing-progress'>
                    <div className='scrollable'>

                        <p className='course-progress-cert'>Module Progress</p>
                        <div className='cource-circular-progress' style={conicstyle}>
                            <p className='percentage-done'>{`${getPercent()}`}%</p>
                        </div>

                        <div className='quizes-videos-lesson-whole'>
                            <div className='quizes-videos-lesson'>
                                <p className='quizes-lesson'>Quizzes completed</p>
                                <p className='videos-lesson'><strong>{giveUserQuizProg()}</strong></p>
                            </div>

                            <div className='quizes-videos-lesson'>
                                <p className='quizes-lesson'>Videos completed</p>
                                <p className='videos-lesson'><strong>{giveUserVideoProg()}</strong></p>
                            </div>
                        </div>

                        <div className='line'></div>
                        <div className='videos-catalogue-lesson'>
                            <p className='lesson-heading'><strong>Lesson</strong></p>
                            
                            {otherLessons.map( lesson => (
                                <a href={`/course/${courseID}/${lesson[0][0]}`}>
                                    <div className={`lesson-name-padlock ${highlightLessonName(lesson[0][0],lessonID)}`}>
                                        <p className='lesson-name'>{`${lesson[0][0]} - ${styleText(lesson[0][1])}`}</p>
                                        { status === "nahh_bro" ? ((lesson[0][0] == 2 && !excludedCourses.includes(courseID)) ? <span>Free</span>:<BiLockAlt/>) : <></>}
                                    </div>
                                </a>
                            ))}

                        </div>


                    </div>


                </div>

                <div className='course-modules'>
                    
                <a href='/course'>
                            <div className='to-home-from-module'>
                                <FaArrowLeft className='lesson-change-icon'/>
                                <p className='to-home-from-module-text'><strong>Course Listing</strong></p>
                            </div>
                        </a>

                    {status && ((userEmailSamll && status !== "nahh_bro") || (lessonContent.lindex === 2 && !(excludedCourses.includes(courseID))) ) ? 
                        
                        (lessonContent &&
                            <div className='course-module-intro'>
                                {/* <p className='course-module-intro-title'>1. Introduction</p> */}
                                <p className='course-module-intro-title'>{`${lessonContent.lindex}. ${lessonContent.ltitle}`}</p>
                                <p className='course-module-intro-desc'>{`${lessonContent.ldesc}`}</p>
                                <div className='course-module-intro-img'>
                                    {/* <img src={Wom}/> */}
                                    {/* <iframe width="100%" height="100%" src={`$
                                    {lessonContent.urlvid}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    <video width="100%" onContextMenu={e => e.preventDefault()} maxHeight="400px" controls controlsList="nodownload" poster={thumb}>
                                        <source src ={lessonContent.urlvid} type="video/mp4"/>
                                    </video>
                                </div>
                                <div className='colab-video-checkmark'>
                                    <a href='https://colab.research.google.com/' target='_blank'>
                                        <div className='colab-btn'>
                                            <BsCodeSlash className='code-icon'/>
                                            <p className='colab-lnk'>Open Colab</p>


                                        </div>
                                    </a>
                                    <div className='marked-watch'>
                                        <p className='colab-lnk'>Marked as Watched</p>
                                        {getVidStat()}
                                    </div>
                                </div>

                                <div className='key-lessons'>
                                    <p className='key-lessons-title'>Key Lessons</p>
                                    <div className='key-lessons-desc'>
                                        <p className='key-lessons-text'>{`${lessonContent.lmains}`}</p>
                                    </div>
                                </div>

                                <div className='quiz-status-link'>
                                    {(lessonContent.qlink && lessonContent.qlink !=="NA" ) && 
                                    <>
                                    
                                        <div className='quiz-complete'>
                                            {getQuizStat()}
                                        </div>

                                        <div className='quiz-link-btn'>
                                            <a href={`/quiz/${courseID}/${lessonContent.qlink}`}><p className='quiz-lnk'>Take quiz</p></a>
                                            <FaArrowRight/>
                                        </div>
                                    </>
                                    }
                                </div>

                            </div>
                        )
                    
                    : 

                    (lessonContent &&
                            <div className='course-module-intro'>
                                {/* <p className='course-module-intro-title'>1. Introduction</p> */}
                                <p className='course-module-intro-title'>{`${lessonContent.lindex}. ${lessonContent.ltitle}`}</p>
                                <p className='course-module-intro-desc'>{`${lessonContent.ldesc}`}</p>
                                <div className='course-module-intro-img'>
                                    {/* <img src={Wom}/> */}
                                    <img src={bthumb}/>
                                </div>

                                <div className='key-lessons'>
                                    <p style={{textAlign:"center"}} className='key-lessons-title'>NOTE:</p>
                                    <div className='key-lessons-desc'>
                                        <p style={{textAlign:"center"}} className='key-lessons-text'>Please purchase the course to unlock the content</p>
                                    </div>
                                </div>

                            </div>
                    )
                    
                    }

                    <div className='switch-lesson'>
    
                    {  
                    (otherLessons.length> 0 && lessonContent.lindex && ((lessonContent.lindex -1) !== 0)) ?
                        <a href={`/course/${courseID}/${lessonContent.lindex-1}`}>
                            <div className='prev-lesson'>
                                <FaArrowLeft className='lesson-change-icon'/>
                                <div className='prev-lesson-name'>
                                    <p className='prev-lesson-name-text'>{`Lesson ${(lessonContent.lindex -1)}`}</p>
                                    <p className='prev-lesson-name-text'><strong>{`${ getRightLst(otherLessons,(lessonContent.lindex -1))[1] }`}</strong></p>
                                </div>
                            </div>
                        </a>

                        : <div></div>
                    }
                    {
                        (otherLessons.length> 0 && lessonContent.lindex >0 && (lessonContent.lindex < otherLessons.length) ) ?
                         <a href={`/course/${courseID}/${lessonContent.lindex+1}`}>
                            <div className='next-lesson'>
                                <div className='next-lesson-name'>
                                <p className='next-lesson-name-text'>{`Lesson ${(lessonContent.lindex + 1)}`}</p>
                                    <p className='next-lesson-name-text'><strong>{`${ getRightLst(otherLessons,(lessonContent.lindex +1))[1] }`}</strong></p>
                                </div>
                                <FaArrowRight className='lesson-change-icon'/>
                            </div>
                        </a>   
                        : <div></div>      
                    }

                    </div>
      
                    
                </div>
                
            </div> 



        </section>)
        :
        <div className='nothing-streatch' style={{height:"100vh",width:"100%"}}></div>    
    }




    </div>
  )
}

export default ModuleOne