import firebase from 'firebase/app'
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCLCCze1EgFz5OSIHAuBzSw0g0llENFLRY",
  authDomain: "project-msimbo.firebaseapp.com",
  databaseURL: "https://project-msimbo-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "project-msimbo",
  storageBucket: "project-msimbo.appspot.com",
  messagingSenderId: "375289166038",
  appId: "1:375289166038:web:6eee00ffa4cf010982f177"
};



//   if (!firebase.apps.length) {
//     const firebaseApp = firebase.initializeApp(firebaseConfig);
//  }else {
//   const firebaseApp =  firebase.app(); // if already initialized, use that one
//  }
// var firebaseApp =firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app(); 
export const fire = firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore();


