import React, {useState, useEffect} from 'react'
import './TiqoEdit.css'
import {db} from './firebase'
import {useParams} from 'react-router-dom'



function TiqoEdit({user}) {

    const [quiz,setQuiz] = useState({})
    const [lstAns,setLstAns] = useState([])
    const [lstQs,setLstQs] = useState([])
    const [modal,setModal] = useState(false);
    const quests = quiz && quiz.questions
	const ans = quiz && quiz.ans
	const {editId} = useParams();



    //console.log(user.email)
    useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(editId).onSnapshot(snapshot => {
            setQuiz({
                //feedback: snapshot.data().feedback,questions
                questions: snapshot.data().questions,
                //img: snapshot.data().imUrl,
                //stat: snapshot.data().status,
                //tl: snapshot.data().title,
                ans: snapshot.data().answers,
            
             
            })

    });


    },[user])

    
    function addAnsLst(event,i){
        let answer_list = ans && [...ans]
        answer_list[i] = event.target.value
        setLstAns(answer_list)
    }

    function addQuesLst(event,i){
        let ques = quests && [...quests]
        ques[i] = event.target.value
        setLstQs(ques)
    }

    function editUpdate() {
        
        if (lstAns.length >0){
            db.collection('user_tiqo').doc(user.email).collection("youtube").doc(editId).set(
                {"answers":lstAns},{merge:true}
                ) 
            toggleModal()
            
            
        }
        if (lstQs.length >0){
            db.collection('user_tiqo').doc(user.email).collection("youtube").doc(editId).set(
                {"questions":lstQs},{merge:true}
                )
            toggleModal()
        }
    }

    const toggleModal = () => {
        setModal(!modal);
      };



  return (
    <div className='main-body-tiqo-edit'>
        <div className='tiqo_edit_collector_whole'>

            <span className='tiqo_edit_title'>Edit Flash Cards</span>
            <a href='/tiqohome'>
                <span className='tiqo_edit_title backhomeqdit'>Back Home</span>
            </a>
            <div className='tiqo_edit_cards_box'>

                {(quests && ans) ? quests.map((quiz,i) => (

                    <div className='edit_card_tiqo'>
                        <div className='card_edit_ques'>
                            <span className='tiqo-edit-question-title'>Question:</span>
                            {/* <p className='tiqo-edit-question'>{quiz}</p> */}
                            {/* <p className='tiqo-edit-question'>{quiz}</p> */}
                            <textarea className='textarea_question' onChange={(event) => addQuesLst(event,i)}>{quiz}</textarea>
                        </div>
                        <div className='card_edit_ans'>
                            <span className='tiqo-edit-answer'>Answer:</span>
                            <textarea onChange={(event) => addAnsLst(event,i)}>{ans[i]}</textarea>
                        </div>
                    </div>

                ))
            
                    :

                    <></>
            }


               

            
            </div>

            {(quests && ans) ?
                <button onClick={editUpdate} className='save-edit-tiqo'>
                    Save changes
                </button>
                :
                <></>    
            }

    {modal && (
        <div className="tiqo-modal">
          <div onClick={toggleModal} className="tiqo-modal-overlay"></div>
          <div className="tiqo-modal-content">
            <h2>Note:</h2>
            <p>
              Update successful
            </p>
            <button className="close-tiqo-modal" onClick={toggleModal}>
              CLOSE
            </button>
          </div>
        </div>
      )}

        </div>
    </div>
  )
}

export default TiqoEdit