import React, { useState,useEffect } from 'react';
import './TiqoLnk.css'
import { AiOutlineSend } from "react-icons/ai"
import { SiOpenai } from "react-icons/si"
import { useNavigate } from 'react-router-dom';
import {db} from './firebase'
import axios from 'axios'

function TiqoLnk({user}) {

  const [inputValue, setInputValue] = useState('');
  const [modal,setModal] = useState(false);
  const [modalStop,setModalStop] = useState(false);
  const [vidsTrans,setVidsTrans] = useState(88)
  const [vidsTransOpen,setVidsTransOpen] = useState(88)
  const [llmupdatd,setllmupdate] = useState([])
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');
  const [renderLink,setRenderLink] = useState("")

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalStop = () => {
    setModalStop(!modal);
  };

  useEffect(() =>{
    db.collection('user_tiqo').doc(user.email).onSnapshot(snapshot => {
        setVidsTrans(snapshot.data().vids)
    
        
      });
      
    },[user])

    useEffect(() =>{
      db.collection('llmupdate').doc("update").onSnapshot(snapshot => {
        setllmupdate(snapshot.data().updates)
      
          
        });
        
      },[user])

  

    useEffect(() =>{
      db.collection('user_tiqo').doc(user.email).onSnapshot(snapshot => {
          setVidsTransOpen(snapshot.data().opentrans)
      
          
        });
        
      },[user])
    
   // console.log(user.email)

function incrementVidTrans() {
  db.collection('user_tiqo').doc(user.email).set(
      {"vids":vidsTrans+1},{merge:true}
  )
}

function incrementGPTVidTrans() {
  db.collection('user_tiqo').doc(user.email).set(
      {"opentrans":vidsTransOpen+1},{merge:true}
  )
}

useEffect(()=>{
  db.collection('render').doc("renderPath").onSnapshot(snapshot => {

      setRenderLink(
          snapshot.data().link
          
      )
      
      
      console.log(renderLink)
  })
},[user])



  const handleSubmit = (event) => {
    event.preventDefault();
    if (vidsTrans >=7){
     // console.log("stop modal")
      toggleModalStop()

    }else{
    //  console.log("continue")

      if ((inputValue.length <10 || inputValue.includes(" ") || selectedValue === "" || getYouTubeVideoId(inputValue) == null)){
  
        alert("Bad input");
        
      }
            else{

              if(selectedValue==="open"){

                  if (vidsTransOpen>0){
                    alert("PLease upgrade to use chatgpt")
                  }else{
                    const apiUrl = `${renderLink}/hello/${getYouTubeVideoId(inputValue)}/${user.email}/${selectedValue}`;
                    //const apiUrl = `https://cors-anywhere.herokuapp.com/`;
                    //axios.get(apiUrl);
                    fetch(apiUrl,{mode: "cors"});
                    incrementVidTrans()
                    incrementGPTVidTrans()
                    toggleModal();
                  }
              }
              else{
                const apiUrl = `${renderLink}/hello/${getYouTubeVideoId(inputValue)}/${user.email}/${selectedValue}`;
                //const apiUrl = `https://cors-anywhere.herokuapp.com/`;
                //axios.get(apiUrl);
                fetch(apiUrl,{mode: "cors"});
                incrementVidTrans()
                toggleModal();
                //navigate('/tiqohome');
              }
      }
    }
  };

  function takeMeHome(){
    navigate('/tiqohome');
  }

  function getYouTubeVideoId(url) {
    var ID = '';
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = null;
    }
    
    
      return ID;
  }


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
//    console.log(inputValue);
  };



  return (
    <div className='tiqo-lnk-main'>
        <div className='tiqo_search'>
            <p className='tiqo-yt-type'>Enter a YouTube link:</p>
            <p className='tiqo-yt-type-nt'>(Minimum length of 6 minutes)</p>
            {(vidsTrans !== 88 && vidsTransOpen !== 88) && 
            
            <>
              {(vidsTransOpen <1) && (<p className='tiqo-yt-type-nt'><SiOpenai style={{paddingRight:"10px",fontSize:"20px",color:"white",position:"relative",top:"3px"}}/>Get ChatGPT to make you one FREE quiz (Limited Version)</p>)}
              <select className='option-llm-tiqo' value={selectedValue} onChange={handleChange}>
                <option value="">Select an LLM Type</option>
                <option value="hug">OpenAssistant (Free - {`${llmupdatd[1]}`})</option>
                <option value="wiz">Base (Best Free- {`${llmupdatd[0]}`})</option>
                <option value="open">ChatGPT (Best overall - {`${llmupdatd[2]}`})</option>
            </select>
              
              <div className='input-yt-dv'>
                  <input type="text" placeholder='Enter Link' id="yt-link-input" value={inputValue} onChange={handleInputChange}  />
                  <AiOutlineSend onClick={handleSubmit} className='send-yt-video'/>
              </div>
              {vidsTrans >=7 && <p className='tiqo-yt-type-nt'>Daily video allocation: {`${vidsTrans}`}/7</p>}
              <a href="/tiqohome"><p className='tiqo-yt-type-nt back-tiqo'>Back to home page</p></a>

            
            </>

}
        </div>
        {modal && (
        <div className="tiqo-modal">
          <div onClick={toggleModal} className="tiqo-modal-overlay"></div>
          <div className="tiqo-modal-content">
            <h2>Note:</h2>
            <p>
              It will take 2-3 minutes to generate the quiz for the video (~1 minute with ChatGPT). Feel free to finish watching the video (or re-watch), and them come back afterwards to test how much you really understood.<br/> Make sure your video has a transcript!
            </p>
            <button className="close-tiqo-modal" onClick={takeMeHome}>
              CLOSE
            </button>
          </div>
        </div>
      )}
      {(modalStop && (vidsTrans>=7) ) && (
        <div className="tiqo-modal">
          <div onClick={toggleModal} className="tiqo-modal-overlay"></div>
          <div className="tiqo-modal-content">
            <h2>Note:</h2>
            <p>
              You are only allowed to transcribe 7 videos a day. <br/>Subscribe to our premium model to transcribe even more!
            </p>
            <button className="close-tiqo-modal" onClick={takeMeHome}>
              CLOSE
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TiqoLnk