import React, {useState, useEffect} from 'react'
import './TiqoFlash.css'
import { BsArrowLeftShort } from "react-icons/bs"
import {db} from './firebase'
import {useParams} from 'react-router-dom'

function Tiqo({user}) {
	const {tiqolnkId} = useParams();
    const [quiz,setQuiz] = useState({})
    const [currentQuiznum,setCurrentQuiznum] = useState(1)
    const [flipped,setfFlipped] = useState(false)


	useEffect(() =>{
        db.collection('user_tiqo').doc(user.email).collection("youtube").doc(tiqolnkId).onSnapshot(snapshot => {
            setQuiz({
                //feedback: snapshot.data().feedback,
                questions: snapshot.data().questions,
                //img: snapshot.data().imUrl,
                //stat: snapshot.data().status,
                //tl: snapshot.data().title,
                ans: snapshot.data().answers,
            
             
            })

    });

    },[user])

   
	const quests = quiz && quiz.questions
	const ans = quiz && quiz.ans

	// if (quiz){
	// 	console.log(ans)
	// 	console.log(quests)
	// }

	const qlength = ans && ans.length;


	const incrementQz = () => {
		if (currentQuiznum < qlength){
			setCurrentQuiznum(currentQuiznum+1);
			if (flipped == true){
				buttonclick();
			}
		}
	}

	const decrementQz = () => {
		if (currentQuiznum > 1){
			setCurrentQuiznum(currentQuiznum-1);
			if (flipped == true){
				buttonclick();
			}
		}
	}

	function buttonclick(){
        var pagebutton= document.getElementById("tiqo-main-label-flashcards");
        pagebutton.click();
		if (flipped == true){
			reverseFlipped()
		}
    }

	function reverseFlipped(){
		setfFlipped(!flipped);
	}

   

  return (
    <div className='tiqoflash-whole'>
        <div >
		<label id="tiqo-main-label-flashcards" className='click-me'>
			<input id="my-cool-input" type="checkbox" />
			<div className="flip-card" onClick={reverseFlipped}>
				<div class="front">
					<br/>
					<br/>
					
					{qlength ? <h1>Question {`${currentQuiznum}`}/{`${qlength}`}:</h1> : <></>}
					<br/>
					<br/>
					<br/>
					<div className='answer-tiqo'>
						{ (quests) ? <p className='q_anser'>{`${quests[currentQuiznum-1]}`}</p> : <></>}

					</div>
				</div>
				<div className="back">
					<h1>Answer:</h1>
					<br/>
					<br/>
					<br/>
					<div className='answer-tiqo'>

						{ (quests) ? <p className='q_anser'>{`${ans[currentQuiznum-1]}`}</p> : <></>}
					</div>
				</div>
			</div>
		</label>
		</div>
			<div className='nav-tiqo'>
				<div className='buttons-tiqo-nav'>
					<button onClick={decrementQz} className='neej'>Back</button>
					<button onClick={incrementQz} className='neej'> Next</button>
				</div>
				<a className='back-to-main-tiqo' href="/tiqohome">Back to home page</a>
			</div>
    </div>
	
  )
}

export default Tiqo