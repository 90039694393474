import React from 'react'
import './login.css'

function Login(
   { email,
    setEmail,
    pass,
    setPass,
    rePass,
    setRePass,
    handleLogin,
    handleSignup,
    hasAccount,
    setHasAccount,
    emailError,
    passError,}
) {
    return (
        <>
        <div className='login_form'>
            <div className='Login_container'>
                <a href='/'><img className='avatar' src='https://media.istockphoto.com/vectors/purple-user-icon-in-the-circle-a-solid-gradient-vector-id1095289632?b=1&k=6&m=1095289632&s=170667a&w=0&h=g0fIdNOkwsl_K3q1MuAHs9krp1nXqLJUxv2_b-zIkIQ=' alt='login'/></a>
                {!hasAccount ? 
                    <h1 style={{textAlign:'center', fontSize:'medium'}}>Sign Up Here</h1>
                :
                    <h1 style={{textAlign:'center',fontSize:'medium'}}>Log In Here</h1>                
                }
                
                <div className='email_section'>
                    <label style={{fontSize:"medium"}} className='email_label'>Email</label>
                    <input
                    className='email_input'
                    required
                    placeholder='Enter Email'
                    type='text'
                    autoFocus
                    value={email}
                    onChange ={(e)=>setEmail(e.target.value)}
                    />
                    <p className='err_msg'>{emailError}</p>
               </div>
               <div className='pass_section'>
                    <label style={{fontSize:"medium"}}>Password</label>
                    <input
                    placeholder='Enter Password'
                    className='pass_input'
                    type='password'
                    value={pass}
                    onChange={(e)=>setPass(e.target.value)}
                    />
                    <p className='err_msg'>{passError}</p>
                </div>

                {!hasAccount ? 
                    <div className='pass_section'>
                        <label style={{fontSize:'medium'}}>Re-enter Password</label>
                        <input
                        placeholder='Enter Password'
                        className='pass_input'
                        type='password'
                        value={rePass}
                        onChange={(e)=>setRePass(e.target.value)}
                        />
                        <p className='err_msg'>{passError}</p>
                     </div>
                :
                    <></>                
                }
                {!hasAccount ? 
                    <div style={{display:"flex",flexDirection:"column"}} className='login_button'>
                        <p>Have an account? <span className='changelogintype' style={{color:"#FF443A",textDecoration:"underline"}} onClick={() =>setHasAccount(!hasAccount)}>Log in</span></p>
                        <button className='input_login' type='submit' onClick={handleSignup}>Sign Up</button>
                    </div>
                :
                    <div style={{display:"flex",flexDirection:"column"}} className='login_button'>
                        <p>Don't have an account? <span className='changelogintype' style={{color:"#FF443A",textDecoration:"underline"}} onClick={ () => setHasAccount(!hasAccount)}>Sign Up</span></p>
                        <button className='input_login' type='submit' onClick={handleLogin}>Log In</button>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default Login
